<template>
    <div class="login">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>电力试验装备智能管理系统</span>
            </div>
            <el-form label-width="80px" :model="form" ref="form" :rules="rules">
                <el-form-item label="用户名" prop="u" >
                    <el-input v-model="form.u" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="p">
                    <el-input type="password" v-model="form.p" placeholder="请输入密码" @keyup.enter.native="handLogin('form')" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="warning" @click="handLogin('form')">登录</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
// import axios from 'axios';
import { nameRule, passRule } from '../utils/vaildate.js'
import { setToken } from '@/utils/setToken.js'
import { login, keyData } from '@/api/login.js'
export default {
    data() {
        return {
            form: {
                u: '',
                p: ''
            },
            rules: {
                u: [{ validator: nameRule, trigger: 'blur' }],
                p: [{ validator: passRule, trigger: 'blur' }]
            }
        };
    },
    methods: {
        handLogin(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    const jse = new this.$jsEncrypt() // 实例化一个 jsEncrypt 对象
                    jse.setPublicKey(keyData)
                    const data = { ...this.form }
                    data.p = jse.encrypt(this.form.p)
                    login(data).then(res => {
                        if (res.code === '000000' && res.result) {
                            setToken('token', res.data.token);
                            setToken('userInfo', res.data.userInfo)
                            setToken('moduleVoList', res.data.moduleVoList)
                            window.sessionStorage.setItem("isLogin", res.data.token)
                            this.$message({ message: res.msg, type: 'success' });
                            console.log('this.$route')
                            console.log(this.$route)
                            this.$router.push('/home')
                        } else {
                            this.$message({ message: res.msg, type: 'error' });
                        }
                    }).catch(err => {
                        this.$message({ message: err, type: 'error' });
                        throw err
                    })


                } else {
                    console.error(this.form);
                }
            })
        },
    },

}
</script>

<style lang="scss">
.login {
    width: 100%;
    height: 100%;
    position: absolute;
    // background: url(../assets/img/bg.jpg) 100% 100% no-repeat;
    background-color: rgb(0, 101, 105);

    .el-card {
        background: #65768557;
    }

    .box-card {
        width: 450px;
        margin: 200px auto;
        color: #fff;

        .el-form .el-form-item__label {
            color: #fff;
        }

        .el-card__header {
            font-size: 34px;
        }

        .el-button {
            width: 100%;
        }

    }
}
</style>
